/* eslint-disable @typescript-eslint/no-floating-promises */
import React, { useEffect, useState } from "react";
import axios from "axios";
import { API_URL, GATSBY_SIGNATURE } from "@/npc-api/config";
import { type RepairService } from "@/types";
import Pagination from "@/components/Common/Pagination";
import { isBrowser } from "@/components/Header";

interface RepairServicesProps {
  results: RepairService[];
  total: number;
}

const RepairServices = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [repairServices, setRepairServices] = useState<RepairServicesProps>({
    results: [],
    total: 0,
  });

  const storedSearch =
    isBrowser && window.localStorage.getItem("search") !== null
      ? String(window.localStorage.getItem("search"))
      : "";
  const [search, setSearch] = useState(storedSearch);

  const storedPage =
    isBrowser && window.localStorage.getItem("currentPage") !== null
      ? Number(window.localStorage.getItem("currentPage"))
      : 1;

  const [page, setPage] = useState<number>(storedPage);

  const MAX_SIZE = 9;

  const fetchRepairServ = async () => {
    try {
      setIsLoading(true);
      const res = await axios.get(`${API_URL}/repair-services`, {
        params: {
          search,
          signature: GATSBY_SIGNATURE,
          page: page - 1,
          pageSize: MAX_SIZE,
        },
      });
      setRepairServices({
        results: res.data.results,
        total: res.data.total,
      });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleSearch = () => {
    window.localStorage.setItem("search", search);
    window.localStorage.removeItem("currentPage");
    setPage(1);

    fetchRepairServ();

    if (search === "") {
      window.localStorage.removeItem("search");
    }
  };

  const handleChangePage = (newP: number) => {
    window.localStorage.setItem("currentPage", JSON.stringify(newP));
    setPage(newP);
  };

  useEffect(() => {
    fetchRepairServ();
  }, [page]);

  return (
    <div className="rts-repair-serv-area pt--50 pb--60">
      <div className="container" style={{ maxWidth: "834px" }}>
        <div className="repair-form-wrapper">
          <div className="rs-form-group col-lg-6 col-sm-12">
            <label htmlFor="emailInput1" className="label-search-form">
              Search
            </label>
            <input
              onChange={(e) => {
                setSearch(e.target.value);
              }}
              value={search}
              type="text"
              className="form-control common-inpt in-1"
              id="textInput1"
              placeholder="Repair"
            />
          </div>
          <div className="rts-form-group col-lg-6 col-sm-12 btn-wrapper">
            <button
              className="btn-search-form "
              disabled={isLoading}
              style={{ flex: "0 0 auto" }}
              onClick={() => {
                handleSearch();
              }}
            >
              <img src="/images/careers/search-ico.svg" alt="Image" />
              <span className="text-btn">
                {isLoading ? "Loading..." : "Filter search"}
              </span>
            </button>
          </div>
        </div>

        {isLoading ? (
          <h3>Loading...</h3>
        ) : repairServices.total === 0 ? (
          <h3>NO REPAIR SERVICES AVAILABLE</h3>
        ) : (
          <>
            <hr className="mb--20" />
            {repairServices?.results.map((serv) => (
              <div className="service-wp" key={serv.id}>
                <h2>{serv.name}</h2>
                <p>{serv.description}</p>
                <hr className="mt--20 mb--20" />
              </div>
            ))}
            <Pagination
              page={page}
              maxPage={Math.ceil(repairServices.total / MAX_SIZE)}
              onPageChange={(newP) => {
                handleChangePage(newP);
              }}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default RepairServices;
