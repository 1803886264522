import Breadcrumb from "@/components/Common/Breadcrumb";
import Layout from "@/components/Layout";
import SEO from "@/components/seo";
import React, { useEffect } from "react";
import { useLocation } from "@reach/router";
import RepairServices from "@/components/Capabilities/RepairServices";

const RepairServicesP = () => {
  const cleanupLocalStorage = () => {
    if (typeof window !== "undefined") {
      window.localStorage.removeItem("search");
      window.localStorage.removeItem("currentPage");
    }
  };

  useEffect(() => {
    cleanupLocalStorage();
  }, []);

  return (
    <Layout>
      <SEO title="Repair Services" canonical={`${useLocation().host}`} />
      <Breadcrumb
        name="Repair Services"
        pathName="Repair Services"
        imgUrl="/images/fotter-sub.jpeg"
      />
      <RepairServices />
    </Layout>
  );
};

export default RepairServicesP;
